import NavMenu from './NavMenu';
import React from 'react';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { connect, MapStateToPropsParam } from 'react-redux';
import { actionCreators, ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';

interface StateProps {
    tsepScriptPath: string;
}

interface DispatchProps {
    generateTsepManifest: typeof actionCreators.transaction.generateTsepManifest;
}

type Props = StateProps & DispatchProps;

interface State {
    tsepManifest: string;
}

class Layout extends React.PureComponent<Props, State, { children?: React.ReactNode }> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tsepManifest: '',
        };
    }

    componentDidMount = async () => {
        try {
            const manifest = await this.props.generateTsepManifest();
            const obj = JSON.parse(JSON.stringify(manifest));
            this.setState({ tsepManifest: obj.generateTsepManifest });
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { tsepScriptPath } = this.props;
        return (
            <div className="d-flex flex-column h-100">
                <Helmet>
                    <script src={`${tsepScriptPath}${this.state.tsepManifest}`}></script>
                    <script>function tsepHandler(eventType, event){}</script>
                </Helmet>
                <NavMenu />
                <div className="flex-fill">{this.props.children}</div>
                <Footer />
            </div>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state) => ({
    tsepScriptPath: state.configuration.config.tsepScriptPath,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            ...actionCreators.transaction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
